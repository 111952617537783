export default {
    ra: {
        page: {
            dashboard: 'トップページ',
        }
    },
    resources: {
        cards: {
            name: 'カード',
            fields: {
                id: '型番',
                name: 'カード名',
                class: '[クラス]',
                subclass: 'サブクラス',
                note: 'レギュレーション',
                level: '星/R/L',
                attr: '属性',
                type: '種族',
                avg: '平均',
                atk: '攻撃力',
                def: '守備力',
                _1: '価格取得日時',
                name_en: 'name',
                pid: 'パック名',
                prices: '価格',
                image: '画像',
            },
        },
        packs: {
            name: 'パック',
            fields: {
                id: '略号',
                name: 'パック名',
            },
        },
        shops: {
            name: '店舗',
            fields: {
                url: 'URL',
                name: '店名',
            },
        },
        prices: {
            name: '価格',
            fields: {
                sid: '店名',
                cid: '型番',
                name: 'カード名',
                price: '価格',
                stock: '在庫',
            },
        },
        decksaggre: {
            name: '上位入賞デッキ',
            fields: {
                name: 'デッキ名',
                count: '入賞数',
                percent: '百分率',
                updatedAt: '更新日時',
                rid: 'レギュレーション',
            },
        },
        cmaps: {
            name: '型番マップ',
            fields: {
                id: 'ID',
                cid: '型番'

            },
        },
        posts: {
            name: 'ご意見板',
            fields: {
                date: '投稿日',
                id: 'ID',
                title: 'タイトル',
                body: '本文',
                commentable: '既読',
                answer: '対応',

            },
        },
        pricelogs: {
            name: '価格推移',
            fields: {
                id: 'ID',
                date: '更新日',
                price: '価格',
                stock: '在庫',
                updatedAt: '更新日時',
            },
        },
        cardlogs: {
            name: 'カードログ',
            fields: {
            },
        },
        sitelogs: {
            name: 'サイトログ',
            fields: {
                id: 'ID',
                title: '本文',
            },
        },
        crawllogs: {
            name: 'クロールログ',
            fields: {
                cid: '型番',
                name: 'カード名',
                updatedAt: '更新日時',
                time: '秒',
                count: '件',
                min: '最低',
                max: '最高',
                avg: '平均',
            },
        },
        minmaxs: {
            name: '価格推移',
            fields: {
                id_: '型番',
                name: 'カード名',
                date: '更新日',
                updatedAt: '更新日時',
                count: '件',
                min: '最安値',
                max: '最高値',
                avg: '平均値',
                aaa: '型番',
            },
        },
        qas: {
            name: 'FAQ',
            fields: {
                date: '投稿日',
                id: 'ID',
                title1: '質問',
                title2: '回答',
            },
        },
        decks: {
            name: 'デッキ',
            fields: {
                did: 'デッキ名',
                name: 'デッキ名',
                date: '開催日',
                csname: '大会名',
                player: 'プレイヤー名',
                rank: '順位',
                izazin: 'イザジン',
                q: '検索',
                csid: '大会名',
                rid: 'レギュレーション',
            },
        },

        conventions: {
            name: '大会',
            fields: {
                id: 'ID',
                name: '大会名',
                date: '開催日',            
                topdecks: '上位入賞デッキ'
            },
        },
        regulations: {
            name: 'レギュレーション',
            fields: {
                id: 'ID',
                name: 'レギュレーション',
                date: '施行日',
                decks: 'シェア率5%以上のデッキ',  
            },
        },



    },
}



// prices: {
//     name: 'サイドバー(テーブル名)が変わる',
//     fields: {
//         name: '中身(カラム)が変わる',        : '',