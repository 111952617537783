// in src/Dashboard.js
import Card from '@material-ui/core/Card';
import moment from 'moment-timezone';
import numeral from 'numeral';
import React, { Component } from 'react';
import dataProvider from './dataProvider';
import MyCard from './fields/MyCard';
import MyPrice from './fields/MyPrice';
import DeckTrend from './charts/DeckTrend';

const tz = 'Asia/Tokyo';

// export default () => (
//     <Card>
//         <CardHeader title="遊戯王カード☆検索 へようこそ" />
//         <CardHeader title="カード更新情報" />
//         <CardContent>[11/11] 現時点で判明している「LINK VRAINS PACK 2」のカード情報を追加しました。</CardContent>
//         <CardHeader title="サイト更新情報" />
//         <CardContent>α版公開しました(2018/12/01)</CardContent>

//     </Card>
// );


const price = obj => obj ? numeral(obj).format('0,0') : '';
const date = str => moment(str).tz(tz).format('MM/DD');
const time = str => moment(str).tz(tz).format('MM/DD HH:mm');

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { cardlogs: [], sitelogs: [], crawllogs: [] };
        this.reload = this.reload.bind(this);
    }

    async reload(type) {
        const pagination = { page: 1, perPage: 10 };
        const sort = { field: 'id', order: 'DESC' };
        const { data } = await dataProvider('GET_LIST', type, { pagination, sort });
        this.setState({ [type]: data });
    }

    async componentDidMount() {
        const set = c => {
            this.reload(c);
            this[c] = setInterval(() => this.reload(c), 60000);
        }

        this.reload('cardlogs');
        this.reload('sitelogs');
        set('crawllogs');
    }

    componentWillUnmount() {
        const reset = c => {
            clearInterval(this[c]);
            delete this[c];
        }
        reset('crawllogs');
    }

    render() {
        return (
            <Card>
                <div style={{ margin: "10pt" }}>
                    <h1>遊戯王OCGカード検索</h1>
                    <h3>遊戯王OCGカード検索 へようこそ</h3>
                    <ul>
                        <li>当サイトは『遊戯王OCG デュエルモンスターズ』の情報サイトです。</li>
                        <li>スマートフォンをお使いの方は画面左上の『≡』マークをタップすると、各種機能を利用できます。</li>
                        <li>ご意見、ご要望、不具合報告等は「ご意見板」からご送信ください。<a href="/privacy.html">プライバシーポリシー</a></li>
                        <li>当サイトのご利用によって生じたあらゆる損害に対して責任は負いかねますのでご承知おきください。</li>
                        <li>当サイトの平均は3個トリム平均を表します。(母数が6以下であれば2個トリム平均、4以下であれば1個トリム平均、2以下であれば平均)</li>
                        <li>広告主様募集しております。</li>
                    </ul>


                    <h3>【価格更新情報】</h3>
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ width: '70pt' }}>日時</th>
                                <th style={{ width: '70pt' }}>型番</th>
                                <th style={{ width: '280pt' }}>名称</th>
                                <th style={{ width: '40pt' }}>最低</th>
                                <th style={{ width: '40pt' }}>最高</th>
                                <th style={{ width: '40pt' }}>平均</th>
                                <th style={{ width: '40pt' }}>件数</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.crawllogs.map(log => (
                                <tr key={log.id}>
                                    <td className="monoCenter">{time(log.updatedAt)}</td>
                                    <td className="mono">{log.cid}</td>
                                    <td><MyCard record={log} label="カード名" source="cid" /></td>
                                    <td className="number">{price(log.min)}</td>
                                    <td className="number">{price(log.max)}</td>
                                    <td className="number">{price(log.avg)}</td>
                                    <td className="number">{price(log.count)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h3>【カード更新情報】</h3>
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ width: '70pt' }}>日時</th>
                                <th style={{ width: '70pt' }}>型番</th>
                                <th style={{ width: '280pt' }}>名称</th>

                            </tr>
                        </thead>
                        <tbody>
                            {this.state.cardlogs.map(log => (
                                <tr key={log.id}>
                                    <td className="monoCenter">{time(log.updatedAt)}</td>
                                    <td className="mono">{log.cid}</td>
                                    <td><MyCard record={log} label="カード名" source="cid" /></td>

                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h3>【レギュレーション別上位入賞デッキ推移】</h3>
                    <DeckTrend perPage={10} percent={5} type={"rid"} sum={false} height={400} />

                    <h3>【週別上位入賞デッキ推移】</h3>
                    <DeckTrend perPage={5} percent={5} type={"week"} sum={true} height={500} />
                    {/* perPage={x軸の個数} percent={％以上のデッキを表示する} type={"week"} sum={trueにすると累積} */}

                    <h3>【サイト更新情報】</h3>

                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ width: '70pt' }}>日時</th>
                                <th style={{ width: '70pt' }}>Ver.</th>
                                <th style={{ width: '280pt' }}>内容</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.sitelogs.map(log => (
                                <tr key={log.id}>
                                    <td className="monoCenter">{date(log.date)}</td>
                                    <td className="monoCenter">{log.title}</td>
                                    <td>{log.body}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        )
    }
}

export default Dashboard;

/*
//折り畳み展開ポインタ
<div onclick="obj=document.getElementById('open').style; obj.display=(obj.display=='none')?'block':'none';">
<a style="cursor:pointer;">▼ クリックで展開</a>
</div>
//折り畳み展開ポインタ

//折り畳まれ部分
<div id="open" style="display:none;clear:both;">


<href>自由に記述してください</href>

</div>
// 折り畳まれ部分
*/