// in src/decks.js
import icon from '@material-ui/icons/Book';
import React from 'react';
import { BooleanInput, Datagrid, DisabledInput, Edit, Filter, FunctionField, List, NumberField, ReferenceField, ReferenceInput, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';


const DeckFilter = props => (
    <Filter {...props}>
        <ReferenceInput source="rid" reference="regulations" alwaysOn >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="name" reference="decksname" perPage={150}　sort={{ field: 'count', order: 'DESC' }} alwaysOn>
            <SelectInput optionText="id" />
        </ReferenceInput>
        <ReferenceInput source="csid" reference="conventions" sort={{ field: 'date', order: 'DESC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

//https://www.izazin.com/taikai/16435/recipe

const list = props => (
    <List {...props} filters={<DeckFilter />}>
        <Datagrid>
            <ReferenceField source="rid" reference="regulations" linkType="show" >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="date" />
            
            <TextField source="name" />
            <TextField source="csname" />
            <NumberField source="rank" />
            <TextField source="player" />
            <FunctionField source="izazin" render={({ id }) => <a target="_new" href={`https://www.izazin.com/taikai/${id}/recipe`}>リンク</a>} />


        </Datagrid>
    </List>
);


const create = undefined;


//大文字注意
//app.jsを二つ更新する
const show = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="url" />
        </SimpleShowLayout>
    </Show>
);

const edit = undefined;

export default { list, edit, create, show, icon };