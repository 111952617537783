import React, { Component } from 'react';
import { translate } from 'react-admin';
import { Line } from 'react-chartjs-2';
import chart from '../chart';
import dataProvider from '../dataProvider';

class Chart extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = { minmaxs: [] };
        this.reload = this.reload.bind(this);
    }

    async reload(type, filter = {}) {
        const pagination = { page: 1, perPage: 10, filter };
        const sort = { field: 'id', order: 'ASC' };
        const { data } = await dataProvider('GET_LIST', type, { filter, pagination, sort });
        this.setState({ [type]: data });
        console.log(data);
    }

    async componentDidMount() {
        const { record, source } = this.props;
        const cid = record[source];
        this.reload('minmaxs', { cid });
    }

    render() {
        const { translate } = this.props;

        const { minmaxs } = this.state;

        const labels = minmaxs.map(({ date: v = '' }) => `${v.slice(4, 6)}/${v.slice(6)}`);

        const datasets = [
            {
                // 平均値
                label: translate('resources.minmaxs.fields.avg'),
                data: minmaxs.map(v => v.avg),
            },
            {
                // 最安値
                label: translate('resources.minmaxs.fields.min'),
                data: minmaxs.map(v => v.min),
            }
        ];
        const rad = 360 / datasets.length;

        const chartData = {
            labels,
            datasets: datasets.map((d, i) => {

                // 色
                const [R, G, B] = chart.hsvToRgb(i * rad, 1, 0.75);
                const color = `rgba(${R},${G},${B},0.4)`;

                return Object.assign({
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: color,
                    borderColor: color,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: color,
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 3,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: color,
                    pointHoverBorderWidth: 5,
                    pointRadius: 3,
                    pointHitRadius: 10,
                }, d);
            })
        };

        const options = {
            responsive: true,
            plugins: {
                datalabels: {
                    color: ''
                }
            },
            scales: {
                xAxes: [{
                    ticks: { autoSkip: true }
                }],
                yAxes: [{
                    ticks: {
                        callback: n => n.toLocaleString(),
                    }
                }]
            }
        }

        return (
            <div>
                <br />
                ［注意］この平均値、最安値はレアリティでの区別がありません。
                <div>
                    <Line
                        data={chartData}
                        options={options}
                        //  width={200}
                        height={200}
                    />
                </div>
            </div>
        );
    }
}

export default translate(Chart);
