export default {
    resources: {
        cards: {
            fields: {
                id: 'ID',
                name: 'Name',
                q: 'Serch',
                class: '[Category1]',
                subclass: 'Category2',
                note: 'Limit',
                level: 'Lv/Rank/Link',
                attr: 'Attribute',
                type: 'Type',
                avg: 'AVG',
                atk: 'ATK',
                def: 'DEF',
                _1: 'updated at',
            },
        },
        packs: {
            name: 'Packs',
            fields: {
                id: 'pack name',
                name: 'pack name',
            },
        },
        shops: {
            name: 'Shops',
            fields: {
                url: 'URL',
                name: 'shop name',
            },
        },
        prices: {
            name: 'Price',
            fields: {
                sid: 'shop name',
                cid: 'card ID',
                name: 'card name',
                price: 'price',
                stock: 'stock',
            },
        },
        decksaggre: {
            name: 'Top deck',
            fields: {
                id: 'deck name',
                count: 'number of prize'

            },
        },
    },
}
