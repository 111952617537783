import React, { Component } from 'react';
import { translate } from 'react-admin';
import { Pie } from 'react-chartjs-2';
import chart from '../chart';
import dataProvider from '../dataProvider';
import 'chartjs-plugin-datalabels';

class Chart extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = { decksaggre: [] };
        this.reload = this.reload.bind(this);
    }

    async reload(type, filter = {}) {
        const pagination = { page: 1, perPage: 10, filter };
        const sort = { field: 'count', order: 'DESC' };
        const { data } = await dataProvider('GET_LIST', type, { filter, pagination, sort });
        this.setState({ [type]: data });
        console.log(data);
    }

    async componentDidMount() {
        const { record, source } = this.props;
        const rid = record[source];
        this.reload('decksaggre', { rid });
    }

    render() {
        // const { translate } = this.props;

        let { decksaggre: data } = this.state;

        data = data.filter(v => v.count); 

        if (data.length === 0) return '';

        const count = data.reduce((m, { count }) => m - count, data[0].total);

        data.push({ name: 'その他', count });

        const labels = data.map(({ name }) => name);

        const rad = 360 / data.length;

        const datasets = [{
            data: data.map(v => v.count),
            backgroundColor: data.map((d, i) => {
                const v = i === data.length - 1 ? 0 : 0.75;
                const [R, G, B] = chart.hsvToRgb(i * rad, 1, v);
                return `rgba(${R},${G},${B},0.4)`;
            }),
        }];

        const chartData = { labels, datasets };

        const options = {
            responsive: true,
            plugins: {
                datalabels: {
                    color: '#666'
                }
            }
        }

        return (
            <Pie
                data={chartData}
                options={options}
                //  width={200}
                height={400}
            />
        );
    }
}

export default translate(Chart);
