// in src/MyUrlField.js
import React from 'react';
import { ReferenceField } from 'react-admin';

const url = {
    srh: '?pid=${lid}',
    happy: 'product/${lid}',
};

const replace = (str = '', map) => {
    return Object.keys(map).reduce((m, k) => {
        return m.replace('${' + k + '}', map[k]);
    }, str);
};

const URL = ({ record, map }) => (
    <a href={record.url + replace(url[record.type], map)} target='_new'>
        {map['name'] || map['lid']}
    </a>
);

const MyUrlField = (props) => {
    const { record } = props;
    return (
        <ReferenceField {...props} source="sid" reference="shops" linkType={false}>
            <URL map={record} />
        </ReferenceField>
    );
};

MyUrlField.defaultProps = {
    addLabel: true,
};

export default MyUrlField;
