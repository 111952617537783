// in src/QAs.js
import icon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import { Datagrid, List, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin';

const list = props => (
    <List {...props} >
        <Datagrid>
            <TextField source="title1" />
            <TextField source="title2" />
            <ShowButton />
        </Datagrid>
    </List>
);

const show = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title1" />
            <TextField source="title2" />
        </SimpleShowLayout>
    </Show>
);

const edit = undefined;
// const edit = props => (

//     <Edit title={<QATitle />} {...props}>
//         <SimpleForm>
//             <DisabledInput source="id" />

//             <TextInput source="title" />

//             <LongTextInput source="body" />
//         </SimpleForm>
//     </Edit>
// );


const create =  undefined;
// props => (
//     <Create {...props}>
//         <SimpleForm>
//             <DisabledInput label="ID" source="id" defaultValue={Date.now() + ''} />


//             <TextInput label="タイトル" source="title" />
//             <LongTextInput label="本文" source="body" />
//         </SimpleForm>
//     </Create>
// );



export default { list, edit, create, show, icon };