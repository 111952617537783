// in src/posts.js
import icon from '@material-ui/icons/Error';
import React from 'react';
import { BooleanField, Create, Datagrid, DateField, DisabledInput, List, LongTextInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';

const list = props => (
    <List {...props} >
        <Datagrid>
            <BooleanField source="commentable" />
            <DateField source="date" />

            <TextField source="title" />
            <TextField source="answer" />
            <ShowButton />
        </Datagrid>
    </List>
);

const show = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <BooleanField source="commentable" />
            <DateField source="date" />
            {/* <TextField source="id" /> */}
            <TextField source="title" />
            <TextField source="body" />
            <TextField source="answer" /> 
        </SimpleShowLayout>
    </Show>
);

const edit = undefined;
// const edit = props => (

//     <Edit title={<PostTitle />} {...props}>
//         <SimpleForm>
//             <DisabledInput source="id" />

//             <TextInput source="title" />

//             <LongTextInput source="body" />
//         </SimpleForm>
//     </Edit>
// );


const create = props => (
    <Create {...props}>
        <SimpleForm>
            <BooleanField source="commentable" />
            <DisabledInput source="id" defaultValue={Date.now() + ''} />
            <DisabledInput source="date" defaultValue={(new Date()).toISOString()} />
            <TextInput source="title" />
            <LongTextInput source="body" />
        </SimpleForm>
    </Create>
);



export default { list, edit, create, show, icon };