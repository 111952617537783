// in src/MyUrlField.js
import React from 'react';
import { Link } from 'react-router-dom';

const MyPrice = ({ record = {}, link }) => (
    <Link to={`/prices?filter={"id_":"${record[link]}"}&order=ASC&page=1&perPage=10&sort=price`}>
        {record['name']}
    </Link>
);

MyPrice.defaultProps = {
    addLabel: true,
};

export default MyPrice;