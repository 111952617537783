// in src/DateTimeField.js
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';

const tz = 'Asia/Tokyo';

const DateField = ({ record = {}, source }) =>
  <Typography>
    {moment(record[source]).tz(tz).format('YYYY/MM/DD HH:mm:ss')}
  </Typography>;


DateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

DateField.defaultProps = {
  addLabel: true,
};

export default DateField;
