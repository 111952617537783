import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import { changeLocale as changeLocaleAction } from 'react-admin';
import { connect } from 'react-redux';

class LocaleSwitcher extends Component {

    changeLocale = (locale) => {
        localStorage.setItem('locale', locale);
        this.props.changeLocale(locale);
    };

    render() {
        return (
            <div>
                <Button onClick={() => this.changeLocale('en')}>English</Button>
                <Button onClick={() => this.changeLocale('ja')}>日本語</Button>
            </div>
        );
    }
}

export default connect(undefined, { changeLocale: changeLocaleAction })(LocaleSwitcher);
