// in src/Cards.js
import icon from '@material-ui/icons/Book';
import React from 'react';
import { Datagrid, Filter, FunctionField, List, NumberField, ReferenceArrayField, ReferenceField, ReferenceInput, SelectInput, Show, ShowButton, SimpleShowLayout, TextField, TextInput } from 'react-admin';
import AvgMinLineChart from '../charts/AvgMinLineChart';
import CardLinkField from '../fields/CardLinkField';
import DateTimeField from '../fields/DateTimeField';
import MyPrice from '../fields/MyPrice';
import ShopLinkField from '../fields/ShopLinkField';
import cards_attr from './cards_attr';
import cards_class from './cards_class';
import cards_subclass from './cards_subclass';
import cards_type from './cards_type';

const CardFilter = (props) => (
    <Filter {...props}>
        <TextInput label="検索" source="q" alwaysOn />
        <TextInput label="型番(前方一致)" source="id_" alwaysOn />
        <TextInput label="カード名(完全一致)" source="name" alwaysOn />
        <TextInput label="card name(完全一致)" source="name.en_" />

        <SelectInput source="class" optionText="id" choices={cards_class} alwaysOn />
        <SelectInput source="subclass" optionText="id" choices={cards_subclass} alwaysOn />
        <SelectInput source="level" />
        <SelectInput source="attr" optionText="id" choices={cards_attr} />
        <SelectInput source="type" optionText="id" choices={cards_type} />
        <TextInput source="atk" />
        <TextInput source="def" />

        <ReferenceInput source="pid" reference="packs" perPage={1000} sort={{ field: 'date', order: 'ASC' }} alwaysOn >
            <SelectInput optionText="id" />
        </ReferenceInput>

    </Filter>
);

const DescField = ({ record, source }) => {
    if (!record[source]) return '';
    const values = record[source].split('\n');
    return <table><tbody>{values.map((v, key) => <tr key={key}><td>{v}</td></tr>)}</tbody></table>;
};
DescField.defaultProps = { addLabel: true };

const JoinField = ({ record, source, join }) => {
    if (!record[source]) return '';
    return record[source].join(join);
};
JoinField.defaultProps = { addLabel: true };

const NameField = (props) => (
    <table>
        <tr><td><MyPrice {...props} source="name" link="id" /></td></tr>
        <tr><td><TextField {...props} source="name_en" /></td></tr>
        <tr><td><FunctionField {...props} source="note" render={({ note }) => note ? `<${note}>` : ''} /></td></tr>
    </table>
)

const ClassField = (props) => (
    <table>
        <tr><td><FunctionField {...props} source="class" render={({ class: c }) => c ? `[${c}]` : ''} /></td></tr>
        <tr><td><JoinField {...props} source="subclass" join="/" /></td></tr>
    </table>
)

const list = props => (
    <List {...props} filters={<CardFilter />}>
        <Datagrid>
            <TextField source="id" />
            <ShowButton />
            <NameField source="name" />
            <ClassField source="class" />
            <NumberField source="level" />
            <TextField source="attr" />
            <TextField source="type" />
            <NumberField source="atk" />
            <NumberField source="def" />
            <DateTimeField source="_1" />
            <NumberField source="avg" />


        </Datagrid>
    </List>
);

const edit = undefined;

const create = undefined;

const Image = (props) => {
    const { record, source } = props;
    const url = 'https://dynayuki.com/yugioh/images/' + record[source]
    return (
        <img src={url} />
    );
};

Image.defaultProps = { addLabel: true };

const show = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <MyPrice source="name" link="id" />
            {/* <Image source="image" /> */}
            <AvgMinLineChart source="id" />
            <TextField source="name_en" />
            <TextField source="note" />
            <TextField source="class" />
            <JoinField source="subclass" join="/" />
            <NumberField source="level" />
            <TextField source="attr" />
            <TextField source="type" />
            <ReferenceField label="効果" source="id" reference="carddescs" linkType={false} >
                <DescField source="desc" />
            </ReferenceField>
            <NumberField source="atk" />
            <NumberField source="def" />
            <TextField source="_1" />
            <NumberField source="avg" />

            <ReferenceArrayField source="prices" reference="prices" linkType={false} >
                <Datagrid>
                    <ShopLinkField source="sid" />
                    <CardLinkField source="name" />
                    <NumberField source="price" />
                </Datagrid>
            </ReferenceArrayField >

        </SimpleShowLayout>
    </Show>
);

export default { list, edit, create, show, icon };
