import React from 'react';
import { ReferenceField } from 'react-admin';

const URL = ({ record, map }) => (
    <a href={record.url} target='_new' >
        {record['name']}
    </a>
);

const MyUrlField = (props) => {
   const  { record } = props;
   return (
        <ReferenceField {...props} reference="shops" linkType={false}>
            <URL map={record} />
        </ReferenceField>
    );
};

MyUrlField.defaultProps = {
    addLabel: true,
};
  
export default MyUrlField;
