// in src/App.js

import deepExtend from 'deep-extend';
import createHistory from 'history/createBrowserHistory';
import enMessages from 'ra-language-english';
import jaMessages from 'ra-language-japanese';
import React from 'react';
import { Admin, AppBar, Layout, resolveBrowserLocale, Resource, Responsive, Sidebar, UserMenu } from 'react-admin';
import ReactGA from 'react-ga';
import Iframe from 'react-iframe';
import './App.css';
import LocaleSwitcher from './cahngeLocale';
import Dashboard from './Dashboard';
import dataProvider from './dataProvider';
import enResource from './i18n/en';
import jaResource from './i18n/ja';
import Cards from './resource/cards';
import Decks from './resource/decks';
import Decksaggre from './resource/decksaggre';
import Minmaxs from './resource/minmaxs';
import Posts from './resource/posts';
import Prices from './resource/prices';
import QAs from './resource/qas';
import Regulations from './resource/regulations';



const history = createHistory();

ReactGA.initialize('UA-132030922-1');


history.listen(location => ReactGA.pageview(location.pathname));


const merge = array => array.reduce((m, r) => deepExtend(m, r) && m, {});

const messages = {
    en: merge([enMessages, enResource]),
    ja: merge([jaMessages, jaResource]),
}

const i18nProvider = locale => messages[locale];

const MyUserMenu = props => (
    <UserMenu {...props}>
        {/* <MenuItemLink to="/configuration" primaryText="Configuration" leftIcon={<SettingsIcon />} /> */}
        <LocaleSwitcher />
    </UserMenu>
);

const amazon = 'https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=9&l=ez&f=ifr&linkID=d0620a48440ac01b990db46badf0bd32&t=exabugs-22&tracking_id=exabugs-22';
const MySidebar = props => {
    return (
        <Responsive
            small={
                <div>
                    <Sidebar {...props} size={200} />
                </div>
            }
            medium={
                <div>
                    <Sidebar {...props} size={200} />
                    <div style={{ margin: 10, maxWidth: 200 }}>
                        {/* <Iframe url={amazon} height="150" display="initial" position="relative" /> */}
                    </div>
                </div>
            }
        />
    )
};

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;
const appLayout = props => <Layout  {...props} appBar={MyAppBar} sidebar={MySidebar} />;


//大文字コピペミス
//Iconは存在するものだけ
const App = () => (
    <Admin appLayout={appLayout}
        history={history}
        locale={localStorage.getItem('locale') || resolveBrowserLocale()}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
    >
        <Resource name="cards"  {...Cards} />
        <Resource name="shops" />
        <Resource name="prices"  {...Prices} />
        <Resource name="minmaxs"  {...Minmaxs} />
        <Resource name="regulations" {...Regulations} />
        <Resource name="decksaggre"  {...Decksaggre} />
        <Resource name="decks"  {...Decks} />
        <Resource name="posts"  {...Posts} />
        <Resource name="qas"  {...QAs} />
        <Resource name="carddescs" />
        <Resource name="conventions" />
        <Resource name="decksname" />
        <Resource name="packs" />


    </Admin>
);

export default App;



//アイコン一覧　https://material.io/tools/icons/?style=baseline