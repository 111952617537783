import icon from '@material-ui/icons/Book';
import React from 'react';
import { ArrayField, Datagrid, List, NumberField, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin';
import DeckRate from '../charts/DeckRate';

const list = props => (
    <List {...props} >
        <Datagrid>
            <TextField source="name" />
            <ArrayField source="decks">
                <Datagrid>
                    <TextField label="resources.decksaggre.fields.name" source="name" />
                    <NumberField label="resources.decksaggre.fields.percent" source="percent" options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }} />
                </Datagrid>
            </ArrayField>
            <ShowButton />
        </Datagrid>
    </List>
);

const show = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <DeckRate source="id" />
        </SimpleShowLayout>
    </Show>
);

const create = undefined;
//  = props => (
//     <Create {...props}>
//         <SimpleForm>
//             <TextInput source="id" />
//             <TextInput source="name" />
//         </SimpleForm>
//     </Create>
// );

const edit = undefined;
//  = props => (
//     <Edit {...props}>
//         <SimpleForm>
//             <DisabledInput source="id" />
//             <TextInput source="name" />
//         </SimpleForm>
//     </Edit>
// );

export default { list, edit, create, show, icon };
