import React, { Component } from 'react';
import { translate } from 'react-admin';
import { Line } from 'react-chartjs-2';
import chart from '../chart';
import dataProvider from '../dataProvider';
import { getYMD, sort } from '../util';

class Chart extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = { datasets: [], labels: [] };
        this.reload = this.reload.bind(this);
    }

    async reload(type, { perPage = 100, filter = {} }) {
        const pagination = { page: 1, perPage };
        const sort = { field: 'id', order: 'DESC' };
        const { data } = await dataProvider('GET_LIST', type, { filter, pagination, sort });
        return data;
    }

    // await aggregate('decksaggre', 'rid', 'regulations');
    // await aggregate('decksweek', 'week', 'weeks');
    // const aggregate = async (out, key, master) => {
    async componentDidMount() {
        // await this._componentDidMount('decksaggre', 'rid', 'regulations');
        //     await this._componentDidMount('decksweek', 'week', 'weeks');
        // }

        // async _componentDidMount(out, key, master) {

        const { perPage = 5, percent = 10, type: key = 'week', sum = false } = this.props;

        const { out, master, sortkeys, labelfix } = {
            rid: {
                out: 'decksaggre',
                master: 'regulations',
                sortkeys: [],
                labelfix: str => {
                    return str.slice(0, 4) + '/' + str.slice(4, 6)
                },
            },
            week: {
                out: 'decksweek',
                master: 'weeks',
                sortkeys: [['rid', -1], ['percent', -1]],
                labelfix: str => {
                    const date = new Date(Number(str) * 7 * 24 * 60 * 60 * 1000);
                    return getYMD(date).slice(1).join('/');
                },
            }
        }[key];

        // perPage : レギュレーションの数
        const masters = await this.reload(master, {
            perPage,
        });
        const rid = masters.map(r => r.id || r._id);

        const decksaggre = await this.reload(out, {
            filter: { [key]: { $in: rid }, percent: { $gt: percent } }
        });

        // ソート
        sort(decksaggre, sortkeys);

        // 「その他」のデータを準備する
        const etc = masters.reduce((m, r) => {
            const item = {
                name: 'その他',
                count: r.total,
                total: r.total,
                [key]: r.id,
            };
            m[r.id] = item;
            decksaggre.push(item);
            return m;
        }, {});

        // (デッキ名,レギュレーション) = 個数 のマップを作る
        const map = {};
        decksaggre.forEach(d => {
            const m = map[d.name] || {};
            m[d[key]] = d.count;
            map[d.name] = m;

            // console.log(d[key]);
            etc[d[key]].count -= d.count;
        });

        const _rid = rid.reverse();

        // [{ デッキ名, 値配列 }] のデータを作成する
        const datasets = [];
        Object.keys(map).forEach(k => {
            const data = [];
            datasets.push({ label: k, data });
            _rid.forEach(r => data.push(map[k][r] || 0));
        });

        // 累積にしたいなら
        sum && datasets.forEach(({ data }) => {
            for (let i = 1; i < data.length; i++) {
                data[i] = data[i - 1] + data[i];
            }
        });

        const labels = _rid.map(r => labelfix(r));

        this.setState({ datasets, labels });

    }

    render() {
        const { height = 600 } = this.props;

        const { datasets, labels } = this.state;
        // console.log(datasets);
        // console.log(labels);

        const rad = 360 / datasets.length;

        const chartData = {
            labels,
            datasets: datasets.map((d, i) => {

                // 色
                const etc = i === datasets.length - 1;
                const s = etc ? 0 : 0.5;

                const [R, G, B] = chart.hsvToRgb(i * rad, s, 0.75);
                const color = (a = 1.0) => `rgba(${R},${G},${B},${etc ? 0.5 : a})`;

                return Object.assign({
                    fill: true,
                    lineTension: 0.1,
                    backgroundColor: color(0.9),
                    borderColor: color,

                    // borderCapStyle: 'butt',
                    // borderDash: [],
                    // borderDashOffset: 0.0,
                    // borderJoinStyle: 'miter',

                    // pointBorderColor: color,
                    // pointBackgroundColor: color,

                    pointBorderWidth: 3,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: color,
                    pointHoverBorderWidth: 5,
                    pointRadius: 0,
                    pointHitRadius: 10,
                }, d);
            })
        };

        const options = {
            responsive: true,
            plugins: {
                datalabels: {
                    color: ''
                }
            },

            scales: {
                xAxes: [{
                    ticks: { autoSkip: true }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        callback: n => n.toLocaleString(),
                    }
                }]
            }
        }

        return (
            <div>
                <Line
                    data={chartData}
                    options={options}
                    //  width={200}
                    height={height}
                />
            </div>
        );
    }
}

export default translate(Chart);
