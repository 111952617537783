// in src/Prices.js
import icon from '@material-ui/icons/AttachMoney';
import React from 'react';
import { Datagrid, Filter, List, NumberField, Show, SimpleShowLayout, TextField, TextInput } from 'react-admin';
import CardLinkField from '../fields/CardLinkField';
import ShopLinkField from '../fields/ShopLinkField';

const PriceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="型番" source="id_" alwaysOn />
        <TextInput source="name" alwaysOn />
    </Filter>
);


const list = props => (
    <List {...props} filters={<PriceFilter />}>
        <Datagrid>
            <ShopLinkField source="sid" />
            <TextField source="cid" />
            <CardLinkField source="name" />
            <NumberField source="price" />
        </Datagrid>
    </List>
);

const create = undefined;

//大文字注意
//app.jsを二つ更新する
const show = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <ShopLinkField label="店名" source="sid" />
            <TextField label="型番" source="cid" />
            <CardLinkField label="カード名" source="sid" />
            <TextField label="価格" source="price" />

        </SimpleShowLayout>
    </Show>
);

const edit = undefined;

export default { list, edit, create, show, icon };