// in src/MyUrlField.js
import React from 'react';
import { Link } from 'react-router-dom';

const MyCard = ({ record = {}, source }) => (
    <Link to={`/cards/${record[source]}/show`} >
        {record['name']}
    </Link>
);

MyCard.defaultProps = {
    addLabel: true,
};

export default MyCard;