import icon from '@material-ui/icons/Equalizer';
import React from 'react';
import { Datagrid, Filter, List, NumberField, ReferenceField, ReferenceInput, SelectInput, Show, SimpleShowLayout, TextField } from 'react-admin';
import DateTimeField from '../fields/DateTimeField';
import MyDeckLink from '../fields/MyDeckLink';

const DecksaggreFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="rid" reference="regulations" alwaysOn >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const list = props => (
    <List {...props} filters={<DecksaggreFilter />}>
        <Datagrid>
            <ReferenceField source="rid" reference="regulations" linkType="show" >
                <TextField source="name" />
            </ReferenceField>
            <MyDeckLink source="name" />
            <NumberField source="count" />
            <NumberField source="percent" options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }} />
            <DateTimeField source="updatedAt" />
        </Datagrid>
    </List>
);

const edit = undefined;

const create = undefined;

const show = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <NumberField source="count" />
            <NumberField source="percent" options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }} />
        </SimpleShowLayout>
    </Show>
);

export default { list, edit, create, show, icon };
