// in src/MyDeckLink.js
import React from 'react';
import { Link } from 'react-router-dom';

const MyPrice = ({ record = {}, link }) => (
    <Link to={`/decks?filter={"name":"${record['name']}", "rid":"${record['rid']}"}&order=DESC&page=1&perPage=10&sort=date`} >
        {record['name']}
    </Link>
);

MyPrice.defaultProps = {
    addLabel: true,
};

export default MyPrice;