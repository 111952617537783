import deepExtend from 'deep-extend';
import { defaults } from 'react-chartjs-2';

const hsvToRgb = (H, S, V) => {
    //https://en.wikipedia.org/wiki/HSL_and_HSV#From_HSV

    const C = V * S;
    const Hp = H / 60;
    const X = C * (1 - Math.abs(Hp % 2 - 1));

    let R, G, B;
    if (0 <= Hp && Hp < 1) {
        [R, G, B] = [C, X, 0]
    }
    if (1 <= Hp && Hp < 2) {
        [R, G, B] = [X, C, 0]
    }
    if (2 <= Hp && Hp < 3) {
        [R, G, B] = [0, C, X]
    }
    if (3 <= Hp && Hp < 4) {
        [R, G, B] = [0, X, C]
    }
    if (4 <= Hp && Hp < 5) {
        [R, G, B] = [X, 0, C]
    }
    if (5 <= Hp && Hp < 6) {
        [R, G, B] = [C, 0, X]
    }

    const m = V - C;
    [R, G, B] = [R + m, G + m, B + m];

    R = Math.floor(R * 255);
    G = Math.floor(G * 255);
    B = Math.floor(B * 255);

    return [R, G, B];
};

export default {
    hsvToRgb,
}

deepExtend(defaults.global, {
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
        callbacks: {
            label: ({ datasetIndex: di, index: i }, data) => {
                const dataset = data.datasets[di]
                return dataset.label + ": " + dataset.data[i].toLocaleString();
            }
        }
    },
});


