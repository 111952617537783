import icon from '@material-ui/icons/LocalAtm';
import React from 'react';
import { Datagrid, Filter, List, NumberField, Show, SimpleShowLayout, TextField, TextInput } from 'react-admin';
import DateTimeField from '../fields/DateTimeField';
import MyPrice from '../fields/MyPrice';


const CardFilter = (props) => (
    <Filter {...props}>
        <TextInput source="id_" alwaysOn />
        <TextInput source="name" alwaysOn />
    </Filter>
);

const list = props => (
    <List {...props} filters={<CardFilter />} >
        <Datagrid>
            <TextField label="resources.minmaxs.fields.aaa" source="cid" />
            <MyPrice label="カード名" source="cid" />
            <TextField source="date" />
            <NumberField source="count" />
            <NumberField source="min" />
            <NumberField source="max" />
            <NumberField source="avg" />

        </Datagrid>
    </List>
);

const edit = undefined;

const create = undefined;

const show = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="cid" />
            <DateTimeField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);


export default { list, edit, create, show, icon };
