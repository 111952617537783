const isNull = s => s === undefined || s === null;

const comp = (a, b, nd) => {
  if (isNull(a)) return nd * 1;
  if (isNull(b)) return nd * -1;
  return (a < b ? -1 : 1)
};

const compare = (a, b, nd) => a === b ? 0 : comp(a, b, nd);

const prop = (o, k) => k.split('.').reduce((o, k) => o && o[k], o);

const _compare = (a, b, c, v) => c ? c[1] * compare(prop(a, c[0]), prop(b, c[0]), c[1]) : v;

const sort = (array, cond) => {
    array.sort((a, b) => cond.reduce((v, c) => v || _compare(a, b, c, v), 0));
};

const getYMD = (now) => {
    const pad = v => ('00' + v).slice(-2);
    const y = String(now.getFullYear());
    const m = pad(now.getMonth() + 1);
    const d = pad(now.getDate());
    return [y, m, d];
};

module.exports = {
    sort,
    getYMD,
  };
